import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  OpenapiSingleton,
  ReactQueryProvider,
  ThemeProvider,
} from '@kaytrust/react-kaytrust-client';
import Wallet from './Wallet/Wallet';
import './index.css';
import MenuItem from './MenuItem/MenuItem';
import CreateIdentifier from './CreateIdentifier/CreateIdentifier';
import AddCredential from './AddCredential/AddCredential';
import PreparePresentation from './PreparePresentation/PreparePresentation';
import IdentifierList from './IdentifierList/IdentifierList';
import IdentifierIssuersList from './IdentifierIssuersList/IdentifierIssuersList';
import CredentialConfigList from './CredentialConfiguration/CredentialConfigList';

OpenapiSingleton.kayTrustIkigai.setBasePath(import.meta.env.VITE_PATH);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ReactQueryProvider>
    <ThemeProvider>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Wallet />} />
          <Route path="/menu-item" element={<MenuItem />} />
          <Route path="/create-identifier" element={<CreateIdentifier />} />
          <Route path="/add-credential" element={<AddCredential />} />
          <Route path="/prepare-presentation" element={<PreparePresentation />} />
          <Route path="/identifier-list" element={<IdentifierList />} />
          <Route path="/issuers-list" element={<IdentifierIssuersList />} />
          <Route path="/credential-config" element={<CredentialConfigList />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </ReactQueryProvider>,
);
